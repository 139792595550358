import { intersectionObserve } from "./_intersection-observer";

const postsBlock = document.querySelector('section.posts-with-featured');

if(postsBlock) {
    let container = postsBlock.querySelector('.posts-wrapper');
    let postOffset = 10;
    let totalPosts = parseInt(container.getAttribute('data-total'));
    let featuredPosts = postsBlock.querySelectorAll('article.featured_post');
    let featuredIDs = [];
    if(featuredPosts && featuredPosts.length > 0) {
        featuredPosts.forEach(function(post) {
            featuredIDs.push(post.getAttribute('id').split('-')[1]);
        });
    }

    function getPosts() {
        let data = new FormData();
        data.append('action', 'get_posts');
        data.append('offset', postOffset);
        data.append('featured_posts', JSON.stringify(featuredIDs));

        postsBlock.querySelector('.loading-spinner').classList.add('show');

        $.ajax({
            url: feltpost_scripts.ajax_url,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            error: function (error) {
                console.log(error);
            },
            complete: function (data) {
                let loadingSpinner = postsBlock.querySelector('.loading-spinner');
                loadingSpinner.classList.remove('show');

                const results = data.responseText;
                container.insertAdjacentHTML('beforeend', results);
                postOffset += 10;
            }
        });
    }

    intersectionObserve(postsBlock.querySelector('.loading-spinner'), 0, 0, (element, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && postOffset < totalPosts) {
                getPosts();
            }
        })(element);
    });
}